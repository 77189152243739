import {createApp} from 'vue'
import App from './App.vue'
import createRouter from './router'
import {createStore} from './store'
import axios from 'axios'
import * as moment from 'moment';
import 'moment/locale/ru';
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

const firebaseConfig = {
    apiKey: "AIzaSyBIqPaiqgKaxa1BGwrYGvNxQplmsJ_w1XI",
    authDomain: "arbitr-crm.firebaseapp.com",
    projectId: "arbitr-crm",
    storageBucket: "arbitr-crm.appspot.com",
    messagingSenderId: "520709066318",
    appId: "1:520709066318:web:224b8abae044b3ef14fd1f"
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const app = createApp(App);

app.config.globalProperties.$moment = moment

app.config.globalProperties.$api = axios.create({
    baseURL: window.location.hostname == 'localhost' ? 'http://localhost/api/' : `https://${window.location.hostname}/api/`,
    timeout: 120 * 1000,
});

const token = localStorage.getItem('user-token');
if (token) {
    app.config.globalProperties.$api.defaults.headers.common['Authorization'] = token
}

const store = createStore(app)
app.use(store);

const router = createRouter(app)
app.use(router);

app.mount('#app');
