import {createStore as createVuexStore} from 'vuex'


export const createStore = (app) => {
    return createVuexStore({
        state: {
            token: localStorage.getItem('user-token') || '',
            userData: {
                roles: []
            },
            notify:{
                title:'',
                content:'',
                type:'success'
            },
            navBarOpened:false,
            isAdmin: function () {
                return this.userData.roles.filter(item => item.name == 'admin').length == 1
            },
            isImplementor: function () {
                return this.userData.roles.filter(item => item.name == 'implementor').length == 1
            },
            isArbiter: function () {
                return this.userData.roles.filter(item => item.name == 'arbiter').length == 1
            },
            isClaimant: function () {
                return this.userData.roles.filter(item => item.name == 'claimant').length == 1
            },
            isDefendant: function () {
                return this.userData.roles.filter(item => item.name == 'defendant').length == 1
            },
            isBuh: function () {
                return this.userData.roles.filter(item => item.name == 'buh').length == 1
            },
            isAgent: function () {
                return this.userData.roles.filter(item => item.name == 'agent').length == 1
            },
        },
        mutations: {
            auth(state, payLoad) {
                state.token = payLoad.token;
                state.userData = payLoad.user;
                localStorage['user-token'] = state.token;
                app.config.globalProperties.$api.defaults.headers.common['Authorization'] = state.token;
            },
            userData(state, payLoad) {
                state.userData = payLoad;
                localStorage['user-token'] = state.token;
                app.config.globalProperties.$api.defaults.headers.common['Authorization'] = state.token;
            },
            logout() {
                this.state.token = '';
                this.state.userData = {};
                localStorage.removeItem('user-token');
            },
            showNotify(state, payLoad) {
                this.state.notify.title = payLoad.title;
                this.state.notify.content = payLoad.content;
                this.state.notify.type = payLoad.type;
            }
        },
        actions: {},
        modules: {}
    })
}
